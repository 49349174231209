Lyte.Component.register("crux-lookuplimit-filter", {
_template:"<template tag-name=\"crux-lookuplimit-filter\"> <lyte-alert lt-prop-show=\"{{lbind(cxPropShow)}}\" lt-prop-show-close-button=\"false\" lt-prop-top=\"0px\" lt-prop-yield=\"true\"> <template is=\"registerYield\" yield-name=\"alert\"> <lyte-alert-header class=\"mB20\"> Lookup Field Max Limit Exceeded </lyte-alert-header> <lyte-alert-content> <div class=\"f15 limitLookup-clr mB15\">Sorry, you have exceeded the maximum limit of five lookup fields.</div> <ul class=\"dTable m0 p0\"><li class=\"limitLookup-formWrap m0 p0 mB15\"><label class=\"limitLookup-formlabel\">Select Column : :</label><div class=\"limitLookup-formvalue crm-font-semibold\">{{cxPropLookups}}</div></li></ul> <div class=\"f15 limitLookup-clr\">Please choose five or less lookup fields to continue.</div> </lyte-alert-content> <lyte-alert-footer class=\"center\"> <lyte-button onclick=\"{{action(&quot;closeModal&quot;)}}\"> <template is=\"registerYield\" yield-name=\"text\">OK</template> </lyte-button> </lyte-alert-footer> </template> </lyte-alert> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"componentDynamic","position":[1]},{"type":"text","position":[3,3,0,1,0]},{"type":"componentDynamic","position":[3]},{"type":"attr","position":[5,1]},{"type":"registerYield","position":[5,1,1],"dynamicNodes":[]},{"type":"componentDynamic","position":[5,1]},{"type":"componentDynamic","position":[5]}]},{"type":"componentDynamic","position":[1]}],
_observedAttributes :["cxPropShow","cxPropLookups"],
	data : function(){
		return {
			cxPropShow:Lyte.attr("boolean",{default:false}),
			cxPropLookups:Lyte.attr("string")
		}		
	},
	actions : {
		closeModal : function(){
			this.setData("cxPropShow",false); //No I18N
		}
		// Functions for event handling
	},
	methods : {
		// Functions which can be used as callback in the component.
	}
});
